import React, { useState, useCallback, useEffect, ChangeEvent } from 'react';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CondOperator, QueryJoinArr } from '@nestjsx/crud-request';
import { StatusCodes } from 'http-status-codes';
import { format, parseISO } from 'date-fns';
import { useHistory } from 'react-router-dom';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import { OrderState } from 'store/ducks/order/types';
import { AppState } from 'store';
import { loadOrders } from 'store/ducks/order/actions';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import { openModal, closeModal } from 'store/ducks/nav/actions';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types/TableTypes';
import { useFilters } from 'hooks/filters';
import ShippingService from 'services/shippingService';
import {
  Order,
  SHIPPING_STATUS,
  TRANSPORT_COMPANIES,
  SHIPPING_TYPE,
} from 'types/generals';
import { useStyles } from '../useStyles';
import { notifySuccess, notifyError } from 'store/ducks/notification/actions';
// import {
//   AutoCompleteDialogProps,
//   AutoCompleteDialogFilter,
// } from 'components/generals/dialog/dialogTypes';
// import AutoCompleteDialog from 'components/generals/dialog/AutoCompleteDialog';
import MyInputFilter from 'components/generals/input/MyInputFilter';
// import { useGetWithSWR } from 'services/apiService';
// import Colors from 'styles/colors';
import { ShippingOneState } from 'store/ducks/shipping/types';
import { GridTitleProps } from './types';
import { loadShippingOneRequest } from 'store/ducks/shipping/actions';
import {
  ORDER_DELETED_ERROR,
  ORDER_DELETED_SUCCESS,
  REMOVE_ORDER_MODAL,
} from 'utils/messages';
import TagWrapper, { DEFINED_TAGS } from 'components/generals/tag';
import Colors from 'styles/colors';
import TimelineOrderModal from 'components/generals/timelineOrderModal';

const OrdersTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  // const [orderOptionsToAutoComplete, setOrderOptionsToAutoComplete] = useState<
  //   AutoCompleteDialogFilter[]
  // >([]);

  const {
    data: orders = [],
    total = 0,
    isLoading,
  } = useSelector<AppState, OrderState>(
    ({ ordersListReducer }: AppState) => ordersListReducer,
  );

  const { data: shipping } = useSelector<AppState, ShippingOneState>(
    ({ shippingReducer }: AppState) => shippingReducer,
  );

  const searchObject = [
    {
      field: 'id',
      operator: CondOperator.EQUALS,
      format: (val: any) => Number(val),
    },
    {
      field: 'orderDetail.userName',
      operator: CondOperator.CONTAINS_LOW,
    },
  ];

  const {
    genericFilterFields,
    sort,
    page,
    handleGenericSearch,
    handleSetValuePage,
  } = useFilters({
    'shipping.id': { filterTitle: 'Signature', value: undefined },
  });

  const handleLoadOrders = useCallback(() => {
    const join: QueryJoinArr[] = [['shipping'], ['orderDetail']];

    if (shipping.transport === TRANSPORT_COMPANIES.CORREIOS) {
      join.push(['sigepLabel']);
    }

    dispatch(
      loadOrders({
        join,
        sort,
        page: page + 1,
        limit: rowsPerPage,
        search: {
          $and: [
            { 'shipping.id': { $eq: shipping.id } },
            { $or: [...genericFilterFields] },
          ],
        },
      }),
    );
  }, [dispatch, sort, page, rowsPerPage, shipping, genericFilterFields]);

  // TODO: Colocar no redux
  // const { data: ordersResponseToAutoComplete } = useGetWithSWR('orders', {
  //   filter: [
  //     {
  //       field: 'shipping.id',
  //       operator: CondOperator.IS_NULL,
  //     },
  //   ],
  // });

  // const handleLoadOrderOptionsToAutoComplete = useCallback(() => {
  //   const ordersList = ordersResponseToAutoComplete?.data.map(
  //     (order: Order) => ({
  //       id: order.id,
  //       title: `${order.id} - ${order.orderDetail.userName}`,
  //     }),
  //   );

  //   setOrderOptionsToAutoComplete(ordersList);
  // }, [ordersResponseToAutoComplete]);

  useEffect(() => {
    handleLoadOrders();
  }, [handleLoadOrders]);

  // useEffect(() => {
  //   if (!isLoading) handleLoadOrderOptionsToAutoComplete();
  // }, [handleLoadOrderOptionsToAutoComplete, isLoading]);

  const handleFormatAmount = (amount: string) => {
    return formatColumnValue(FormatOptions.MONEY, amount);
  };

  const handleRemoveOrderFromShipping = (order: Order) => {
    try {
      dispatch(
        openModal(ConfirmationDialog, {
          title: 'Remover pedido da remessa',
          message: REMOVE_ORDER_MODAL(order.id, shipping.name),
          actionFn: () => removeOrderFromShipping(order),
        }),
      );
    } catch (err) {
      dispatch(notifyError(ORDER_DELETED_ERROR));
    }
  };

  const removeOrderFromShipping = async (order: Order) => {
    try {
      const data = await ShippingService.removeOrderFromShipping(
        Number(shipping.id),
        order.id,
      );

      if (data?.status === StatusCodes.OK) {
        dispatch(notifySuccess(ORDER_DELETED_SUCCESS));
        revalidateShipping();
      } else {
        throw new Error(ORDER_DELETED_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(closeModal());
    }
  };

  // const submitUpdateShipping = async (order: any) => {
  //   const data = await ShippingService.addOrderInShipping(Number(shipping.id), [
  //     order.id,
  //   ]);

  //   if (data?.status === StatusCodes.CREATED) {
  //     dispatch(
  //       notifySuccess(`Pedido ${order.title} foi adicionado com sucesso.`),
  //     );
  //     revalidateShipping();
  //   } else {
  //     dispatch(notifyError(`Falha ao adicionar pedido ${order.title}`));
  //   }
  //   dispatch(closeModal());
  // };

  const revalidateShipping = () =>
    dispatch(loadShippingOneRequest(shipping.id.toString()));

  // const handleAddOrder = () => {
  //   try {
  //     const dialogProps: AutoCompleteDialogProps = {
  //       modalTitle: 'Adicionar Pedido',
  //       modalInfo: 'Selecione o código do pedido desejado',
  //       labelPlaceholder: 'Insira o código do pedido',
  //       options: orderOptionsToAutoComplete,
  //       onlySearch: true,
  //       clearInputBlur: true,
  //       submitAction: (order: any) => submitUpdateShipping(order),
  //     };
  //     dispatch(openModal(AutoCompleteDialog, dialogProps));
  //   } catch (error) {
  //     dispatch(notifyError('Não foi possível adicionar o pedido à Remessa'));
  //   }
  // };

  const formatDate = (data: string) => {
    return format(parseISO(data), 'dd/MM/yyyy');
  };

  const handleSearch = (e: any) => {
    if (e.key === 'Enter' || e.keyCode === '13') {
      handleGenericSearch(e, searchObject);
    }
  };

  const PageTitle = (props: GridTitleProps) => (
    <Typography variant="h2" color="textPrimary" style={{ fontWeight: 'bold' }}>
      {props.title}
    </Typography>
  );

  const handleOpenTimelineModal = (order: Order) => {
    dispatch(openModal(TimelineOrderModal, { order }));
  };

  const redirectToOrderDetail = (orderId: number) => {
    history.push(`/admin/orders/${orderId}`);
  };

  return (
    <>
      <Grid item container alignItems="center" spacing={3}>
        <Grid item>
          <PageTitle title="Pedidos" />
        </Grid>

        <Grid item>
          <MyInputFilter
            onKeyUp={e => handleSearch(e)}
            placeholder={'Buscar'}
          />
        </Grid>

        {/* <Grid item xs>
          <Button className={classes.actionWrapper} onClick={handleAddOrder}>
            <Typography
              variant="h4"
              color="textPrimary"
              style={{
                color: Colors.RED,
                fontWeight: 'bold',
                textTransform: 'capitalize',
              }}
            >
              Adicionar pedido
            </Typography>
          </Button>
        </Grid> */}
      </Grid>

      <Grid className={classes.mTop}>
        <Grid container direction="column">
          <Paper className={classes.paperTable} elevation={0}>
            <TableContainer>
              <Table className={classes.table} aria-label="ordersTable">
                <TableHead>
                  <TableRow>
                    <TableCell>Nº Pedido</TableCell>
                    {!isLoading &&
                    shipping.shippingType === SHIPPING_TYPE.STORE ? (
                      <TableCell>Pedido Venda</TableCell>
                    ) : null}
                    <TableCell>Cliente</TableCell>
                    <TableCell>Pagamento</TableCell>
                    <TableCell>Serviço</TableCell>
                    {!isLoading &&
                    shipping.transport === TRANSPORT_COMPANIES.CORREIOS ? (
                      <TableCell>Rastreamento</TableCell>
                    ) : null}
                    <TableCell>Total</TableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isLoading &&
                    orders?.map(order => (
                      <TableRow key={order.id}>
                        <TableCell
                          className={classes.tableCellBold}
                          onClick={() => redirectToOrderDetail(order.id)}
                        >
                          {order.id}
                          {order.isResend ? (
                            <Grid item xs>
                              <TagWrapper
                                config={DEFINED_TAGS.STATUS}
                                value={'Reenvio'}
                              />
                            </Grid>
                          ) : null}
                        </TableCell>
                        {shipping.shippingType === SHIPPING_TYPE.STORE ? (
                          <TableCell>{order?.pedidoVenda?.id || '-'}</TableCell>
                        ) : null}
                        <TableCell
                          style={{
                            textTransform: 'capitalize',
                            lineHeight: 'normal',
                          }}
                        >
                          {order.orderDetail?.userName || '-'}
                        </TableCell>
                        <TableCell>
                          {formatDate(order.paymentDate) || '-'}
                        </TableCell>
                        {shipping.transport === TRANSPORT_COMPANIES.CORREIOS ? (
                          <>
                            <TableCell>
                              {order?.sigepLabel?.serviceName || '-'}
                            </TableCell>

                            <TableCell>
                              {order?.sigepLabel?.trackingNumber || '-'}
                            </TableCell>
                          </>
                        ) : (
                          <TableCell>
                            {order?.orderDetail.transport || '-'}
                          </TableCell>
                        )}
                        <TableCell>
                          {handleFormatAmount(
                            order?.orderDetail.amount.toString(),
                          )}
                        </TableCell>
                        <TableCell>
                          <Grid container alignItems="center">
                            {shipping.status !== SHIPPING_STATUS.CLOSED ? (
                              <Button
                                className={classes.circle}
                                onClick={() =>
                                  handleRemoveOrderFromShipping(order)
                                }
                              >
                                <DeleteOutlinedIcon className={classes.icon} />
                              </Button>
                            ) : null}
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid container alignItems="center">
                            <Button
                              onClick={() => handleOpenTimelineModal(order)}
                            >
                              <Typography
                                variant="h4"
                                color="textPrimary"
                                style={{
                                  color: Colors.RED,
                                  fontWeight: 'bold',
                                }}
                              >
                                Ver detalhes
                              </Typography>
                            </Button>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container direction="column">
              <TablePagination
                ActionsComponent={MyTablePaginationActions}
                component="div"
                count={total}
                page={page}
                labelRowsPerPage="Itens por página"
                onChangePage={(_e: unknown, newPage: number) =>
                  handleSetValuePage(newPage)
                }
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={(e: ChangeEvent<HTMLInputElement>) => {
                  handleSetValuePage(0);
                  setRowsPerPage(parseInt(e.target.value, 10));
                }}
                rowsPerPageOptions={ROWS_PER_PAGE}
              />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default OrdersTable;
