import { Label } from 'store/ducks/sigep/label/types';
import { Plan, User } from 'types/generals';
import { Debit } from 'types/generals/debit';

import { Address } from './address';
import { Payment } from './payment';
import { Invoice } from './invoice';
import { Shipping } from './shipping';
import { OrderDetail } from './orderDetail';
import { ProductPayment, ResendProductPayload } from './storeProduct';
import { Signature } from './signature';
import { StatusTrackingDetailsProps } from './transport';

export enum ORDER_STATUS {
  // PEDIDO
  SUBMITTED = 'Pedido efetuado',
  // PAGAMENTO
  PENDING = 'Pagamento pendente',
  PAID = 'Pagamento confirmado',
  FAILED = 'Pagamento recusado',
  PENDING_CANCELED = 'Cancelamento pendente',
  // EM SEPARAÇÃO
  IN_DIVISION = 'Em separação',
  // ENVIO
  IN_SHIPPING = 'Em trânsito',
  DELIVERY_FAILURE = 'Falha na entrega',
  WAITING_REMOVE = 'Aguardando retirada',
  RETURNED_SENDER = 'Devolvido ao remetente',
  DELIVERY = 'Saiu para entrega',
  // CONCLUIDO
  DELIVERED = 'Entregue',
  // CANCELADO
  CANCELED = 'Cancelado',

  RESEND = 'Reenvio',
  // DESATIVADO
  PAYMENT_REFUND = 'Pagamento devolvido',
}

export enum ORDER_STEPS_GROUP {
  ORDER,
  PAYMENT,
  SHIPPING,
  SEND,
  DONE,
  CANCELED,
}

export enum ORDER_COLOR_GROUP {
  WAITING = 'waiting',
  FAILED = 'failed',
  DONE = 'done',
}

export enum ORDER_TYPE {
  SIGNATURE = 'Assinatura',
  STORE = 'Loja',
}

export enum ENHANCE_STATUS {
  PARENT_ENHANCED = 'Pedido turbinado',
  WAITING_ENHANCING = 'Aguardando turbinação',
  ENHANCED = 'Turbinado',
  UNENHANCED = 'Turbinação cancelada',
}

export interface StatusHistory {
  id: number;
  newState: string;
  oldState: string;
  details?: string;
  requester: string;
  createdAt: string;
  stepGroup?: ORDER_STEPS_GROUP;
  colorGroup?: ORDER_COLOR_GROUP;
}

export interface StatusCancelHistory extends StatusHistory {
  details: string;
  requester: string;
}

export enum ORDER_REFERENCE_REASON {
  BILLED = 'Fatura',
  RESEND = 'Reenvio',
  ENHANCE = 'Turbinação',
}

export interface ReferenceOrder {
  id: number;
  type: ORDER_TYPE;
  paymentDate: Date;
  amount: number;
  reason: ORDER_REFERENCE_REASON;
}

export enum ORDER_STATUS_COTATION {
  QUOTE_NOT_STARTED = 'cotação não iniciada',
  QUOTING = 'cotando',
  QUOTATION_MADE = 'cotação efetuada',
  QUOTATION_MADE_IN_STORE = 'cotado na loja',
  QUOTE_ERROR = 'erro na cotação',
}

export interface Order {
  id: number;
  createdAt: string;
  updatedAt: string;
  invoices: Invoice[];
  sigepLabel: Label;
  shipping: Omit<Shipping, 'orders'>;
  signature: Signature;
  payments: Payment[];
  paymentDate: string;
  statusHistory: StatusHistory[];
  statusCancelHistory: StatusCancelHistory[];
  statusInternalHistory: StatusCancelHistory[];
  orderDetail: OrderDetail;
  isActive: boolean;
  isFirst: boolean;
  isFirstPaidOrder: boolean;
  isFirstOrderGift: boolean;
  isEnhanced: boolean;
  enhanceStatus?: string;
  status: ORDER_STATUS;
  isResend: boolean;
  isDonation: boolean;
  referenceOrders: ReferenceOrder[];
  orderProductPayment: ProductPayment;
  orderLastPayment: Payment;
  statusTrackingDetails: StatusTrackingDetailsProps[];
  pedidoVenda?: { id: string };
  logErrors: any[];
  oldOrder?: {
    codigo_base: string;
    codigo_pedido: string;
    codigo_assinatura: string;
    nf_bling: string;
    nf_bling_tri: string;
    nf_bling_brinde: string;
    num_remessa: string;
    status_interno: string;
  };
  statusCotation: ORDER_STATUS_COTATION;
  orderIsInShipment: boolean;
  //FIXME: após blackfriday ver uma maneira melhor de pegar o boleto
  bankslip?: string;
}

export interface ResendOrder {
  userId: number;
  productDetails: ResendProductPayload[];
  orderType: string;
  address: Partial<Address>;
  canSaveAddress: boolean;
  referenceOrderIds: number[];
  resendReason?: string;
  transport: string;
}

export interface OrderDetailPlan {
  planName: string;
  planHash: string;
  planDescription: string;
  recurrenceName: string;
  recurrenceMonths: number;
}

export interface OrderDetailProduct {
  productId: number;
  sku: string;
  orderDetail: OrderDetail;
  images: { name: string }[];
  itens: { name: string }[];
  name: string;
  description: string;
  weight: string;
  startDate: string;
  endDate: string;
  productAmount: number;
  productCount: number;
  productUnitPrice: string;
  quantityItems: number;
  isGift: boolean;
  isEnhancedProduct?: boolean;
}

export interface OrdersProps {
  id: number;
  name: string;
  orderType: string;
  createdAt: string;
  subtotal: number;
  plan: Plan;
  user: User;
  orders: Order[];
  debits: Debit[];
  status: string;
}
