import React from 'react';
import {
  Grid,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import { StatusCodes } from 'http-status-codes';
import { useHistory } from 'react-router-dom';

import useStyles from 'components/generals/forms/register/styles';
import MyOutlinedTextField from '../input/MyOutlinedTextField';
import MySelectForm from '../input/MySelectForm';

import { closeModal, openModal } from 'store/ducks/nav/actions';
import {
  disableButton,
  notifyError,
  enableButton,
  notifySuccess,
} from 'store/ducks/notification/actions';
import InvoiceService from 'services/invoiceService';

import { BindInvoiceOrderSchema } from './utils';
import { INVOICE_NATURES } from 'types/generals/invoice';
import { BaseDialogProps } from './dialogTypes';
import ConfirmationDialog from './ConfirmationDialog';
import { BindInvoiceOrderPayload } from './types';
import {
  BIND_INVOICE_ORDER_ASK,
  BIND_INVOICE_SUCCESS,
  BIND_INVOICE_ERROR,
} from 'utils/messages/invoice';

interface IBindInvoiceOrderDialogProps {
  orderId: number;
}

const BindInvoiceOrderDialog: React.FC<IBindInvoiceOrderDialogProps> = ({
  orderId,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const history = useHistory();

  const { errors, handleSubmit, register, control } = useForm({
    validationSchema: BindInvoiceOrderSchema,
  });

  const handleOpenConfirmDialog = (data: BindInvoiceOrderPayload) => {
    const modalProps: BaseDialogProps = {
      title: 'Vinculando nota fiscal',
      confirmText: 'Sim',
      cancelText: 'Não',
      message: BIND_INVOICE_ORDER_ASK(data.blingInvoiceId, orderId),
      actionFn: () => onSubmit(data),
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  const onSubmit = async (data: BindInvoiceOrderPayload) => {
    try {
      dispatch(disableButton());
      const response = await InvoiceService.bindOrderInvoice(orderId, data);

      if (response.status === StatusCodes.CREATED) {
        dispatch(notifySuccess(BIND_INVOICE_SUCCESS));
        history.go(0);
      } else {
        throw new Error(BIND_INVOICE_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(enableButton());
      dispatch(closeModal());
    }
  };

  return (
    <Grid container direction="column">
      <DialogTitle>
        <Grid container justify="space-between">
          <Typography variant="h1">
            Vincular nota fiscal ao pedido {orderId || ''}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item xs>
            <MyOutlinedTextField
              id="invoice-number"
              name="blingInvoiceId"
              label="Id da nota fiscal"
              fullWidth
              error={Boolean(errors.blingInvoiceId)}
              helperText={
                errors.blingInvoiceId ? errors.blingInvoiceId.message : null
              }
              inputRef={register}
            />
          </Grid>

          <Grid item xs>
            <Controller
              name="nature"
              control={control}
              defaultValue=""
              as={({ onChange, onBlur, value }) => (
                <MySelectForm
                  contracted
                  title="Natureza da nota fiscal"
                  itens={Object.values(INVOICE_NATURES).map(nature => ({
                    title: nature,
                    value: nature,
                  }))}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={Boolean(errors.nature)}
                  helperText={errors.nature ? errors.nature.message : null}
                />
              )}
            />
          </Grid>

          <Grid container item justify="flex-end">
            <Button
              className={clsx(styles.buttonLabel, styles.buttonWrapper)}
              variant="contained"
              color="primary"
              onClick={handleSubmit(handleOpenConfirmDialog as any)}
            >
              Confirmar
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Grid>
  );
};

export default BindInvoiceOrderDialog;
