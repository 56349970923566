import { CreateQueryParams } from '@nestjsx/crud-request';
import { GetProps, getWithSaga, api } from './apiService';
import { BindInvoiceOrderPayload } from 'components/generals/dialog/types';

export default class InvoiceService {
  static async getAll(queryParams: CreateQueryParams): Promise<GetProps> {
    try {
      const data = await getWithSaga('invoices', queryParams);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async deleteInvoice(id: number) {
    try {
      return await api.delete(`invoices/${id}`);
    } catch (error) {
      return error.response.data;
    }
  }

  static async bindOrderInvoice(
    orderId: number,
    data: BindInvoiceOrderPayload,
  ) {
    try {
      const response = await api.post(`invoices/bind-order`, {
        orderId,
        blingInvoiceId: data.blingInvoiceId,
        nature: data.nature,
      });

      return response;
    } catch (error) {
      return error;
    }
  }
}
