import React, { FC, useCallback, useEffect } from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@material-ui/core';
import { CondOperator } from '@nestjsx/crud-request';
import { useSelector, useDispatch } from 'react-redux';
import { StatusCodes } from 'http-status-codes';
import { useHistory } from 'react-router-dom';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import MyTypography from 'components/generals/labels/MyTypography';
import CircularLoading from 'components/generals/loading/CircularLoading';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types/TableTypes';
import { OrderItemProps } from './types';
import { useStyles } from './styles';
import Colors from 'styles/colors';
import { AppState } from 'store';
import { InvoiceState } from 'store/ducks/invoice/types';
import { loadInvoiceRequest } from 'store/ducks/invoice/actions';
import { Invoice } from 'types/generals';
import { openModal, closeModal } from 'store/ducks/nav/actions';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import MyTooltip from 'components/generals/tolltip';
import {
  UNBIND_INVOICE_ORDER,
  UNBIND_INVOICE_ERROR,
  UNBIND_INVOICE_SUCCESS,
} from 'utils/messages/invoice';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import InvoiceService from 'services/invoiceService';

const OrderInvoice: FC<OrderItemProps> = ({ order }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: invoices = [], isLoading } = useSelector<
    AppState,
    InvoiceState
  >(({ invoice }: AppState) => invoice);

  const formatPrice = useCallback((amount: string) => {
    return formatColumnValue(FormatOptions.MONEY, amount);
  }, []);

  const openInvoice = (invoiceLink: string) => {
    const win = window.open(invoiceLink, '_blank');
    if (win) win.focus();
  };

  const handleUnbindInvoiceFromOrder = (invoice: Invoice) => {
    try {
      dispatch(
        openModal(ConfirmationDialog, {
          title: 'Remover nota fiscal do pedido',
          message: UNBIND_INVOICE_ORDER(invoice.number, order.id),
          actionFn: () => removeInvoiceFromOrder(invoice.id),
        }),
      );
    } catch (err) {
      dispatch(notifyError(UNBIND_INVOICE_ERROR));
    }
  };

  const removeInvoiceFromOrder = async (invoiceId: number) => {
    const response = await InvoiceService.deleteInvoice(invoiceId);

    if (response.status === StatusCodes.OK) {
      dispatch(notifySuccess(UNBIND_INVOICE_SUCCESS));
    } else {
      dispatch(notifyError(UNBIND_INVOICE_ERROR));
    }
    dispatch(closeModal());
    history.go(0);
  };

  useEffect(() => {
    dispatch(
      loadInvoiceRequest({
        filter: {
          field: 'order.id',
          operator: CondOperator.EQUALS,
          value: order.id,
        },
        join: [['orders']],
      }),
    );
  }, [dispatch, order]);

  return (
    <Grid item xs={12} style={{ marginBottom: '25px' }}>
      <Paper className={styles.paperTable} elevation={0}>
        <MyTypography variant="h3" className={styles.paperInfoTitle}>
          Notas fiscais do Pedido
        </MyTypography>
        <TableContainer>
          <Table className={styles.table} aria-label="order items table">
            <TableHead>
              <TableRow>
                <TableCell>Id Bling</TableCell>
                <TableCell>Número</TableCell>
                <TableCell>Série</TableCell>
                <TableCell>Natureza</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Nota Fiscal</TableCell>
                <TableCell>Chave de Acesso</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <CircularLoading
                isLoading={isLoading}
                title={'Carregando notas'}
              />
            ) : (
              <TableBody>
                {invoices &&
                  invoices.map((invoice: Invoice, key) => (
                    <TableRow key={key}>
                      <TableCell>{invoice?.blingInvoiceId || '-'}</TableCell>
                      <TableCell>{invoice.number}</TableCell>
                      <TableCell>{invoice.series}</TableCell>
                      <TableCell>{invoice.nature}</TableCell>
                      <TableCell>{invoice?.amount || '-'}</TableCell>
                      <TableCell>
                        <Button onClick={() => openInvoice(invoice.linkDanfe)}>
                          <Typography
                            variant="h4"
                            color="textPrimary"
                            style={{
                              color: Colors.RED,
                              fontWeight: 'bold',
                            }}
                          >
                            Ver nota fiscal
                          </Typography>
                        </Button>
                      </TableCell>
                      <MyTooltip
                        title={invoice.chaveAcesso || '-'}
                        enterDelay={500}
                        placement="top"
                      >
                        <TableCell>{invoice.chaveAcesso || '-'}</TableCell>
                      </MyTooltip>
                      <TableCell>
                        <Button
                          className={styles.circle}
                          onClick={() => handleUnbindInvoiceFromOrder(invoice)}
                        >
                          <DeleteOutlinedIcon className={styles.icon} />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
};

export default OrderInvoice;
